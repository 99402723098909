import {queryByClass} from '../shared/util'

let parallaxSplat

function init () {
  parallaxSplat = queryByClass('js-parallax-splat')
  if (!parallaxSplat) return

  scrollListenSplat()
}

function scrollListenSplat () {
  let splatPos = parallaxSplat.offsetTop
  window.addEventListener('scroll', function () {
    let currentYOffset = window.pageYOffset
    parallaxSplat.style.top = ((-currentYOffset * 0.2) + splatPos) + 'px'
  })
}

export default init
